.loading-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(18, 18, 18, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 0;
  margin: 0;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes pulseSpinnerColor {
  0% {
    border-top-color: #95e7ff;
  }
  25% {
    border-top-color: #8fdef4;
  }
  50% {
    border-top-color: #7bbbcd;
  }
  75% {
    border-top-color: #8fdef4;
  }
  100% {
    border-top-color: #95e7ff;
  }
}
.loading-spinner {
  border: 7px solid #6493a1;
  border-top: 7px solid #95e7ff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation:
    spin 1.5s linear infinite,
    pulseSpinnerColor 5s linear infinite;
}
