.card {
  border-radius: 10px;
  position: relative;
}

.card .card-content {
  padding: 20px;
  margin: 0px;
  display: grid;
  grid-template-columns: 70px 2fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 0px;
}

.card .card-busy {
  grid-column: 1 / span 3;
  grid-row: 1 / span 1;
  padding: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  margin: 0px;
}

.card .card-icon {
  grid-column: 1 / span 1;
  grid-row: 1 / span 2;
  place-self: center;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 20px minmax(40px, 1fr);
  grid-template-rows: 1fr;
}

.card .card-icon .offline-icon {
  grid-column: 1;
  font-size: 50px;
  color: var(--Brand3Color);
  text-align: center;
  vertical-align: center;
  place-self: center;
}

.card .card-icon .device-icon {
  grid-column: 2;
  place-self: center baseline;
  width: 40px;
}

.card .card-name {
  grid-column: 2 / span 2;
  grid-row: 1 / span 1;
  font-size: clamp(1.2rem, 2.5vw, 1.5rem);
  cursor: pointer;
  justify-self: baseline;
  align-self: center;
}

.card .card-extra-buttons {
  display: flex;
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
  justify-content: space-between;
  justify-self: end;
  column-gap: 10px;
  align-items: center;
}

.card .card-extra-buttons * {
  flex-shrink: 1;
}

.card .card-control {
  display: flex;
  align-items: center;
  grid-column: 2 / span 2;
  grid-row: 2 / span 1;
  margin-right: 0px;
}

.image-action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  width: clamp(20px, 10vw, 36px);
  height: clamp(20px, 10vw, 36px);
  border-radius: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.image-action-button:active {
  animation: clickAnimation 0.2s ease-in-out;
}

.image-action-button img {
  width: 75%;
  height: 75%;
}

/* Door icon is not the right size and is cropped differently */
.door-card .card-icon .device-icon {
  width: 50px;
  margin-left: -6px;
}

/* Quick actions need to be spaced evenly or they spread out weirdly */
.door-card .card-control {
  justify-content: space-evenly;
}

/* Door icon is not the right size and is cropped differently */
.light-card .card-icon .device-icon {
  width: 55px;
  margin-left: -2px;
}

/* Quick actions need to be spaced evenly or they spread out weirdly */
.light-card .card-control {
  justify-content: space-evenly;
}
