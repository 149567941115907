.device-link-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  justify-content: space-evenly;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  background: linear-gradient(
    to bottom,
    var(--Brand5Color),
    var(--Brand3Color)
  );
  min-height: 65px;
}
.device-link-info-text {
  font-weight: 200;
}

@media only screen and (max-width: 299px) {
  .device-link-info {
    grid-template-columns: 1fr !important;
  }
}

.device-link-info > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 5px;
}

.device-link-device-list-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.device-link-device-list-container .device-list-header {
  display: flex;
  align-items: center;
  justify-content: baseline;
  margin: 10px auto;
  width: 64%;
}

.device-link-device-list-container .device {
  display: flex;
  flex-direction: column;
  margin: 0 19%;
  gap: 7px;
}

.device-link-device-list-container .device-card {
  flex-grow: 1;
}

@media only screen and (max-width: 520px) {
  .device-link-device-list-container .device {
    margin: 0 8px;
  }
  .device-link-device-list-container .device-list-header {
    width: initial;
    margin-left: 20px;
  }
}
