.circular-3d-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circular-3d-button > * {
  border-radius: 50%;
  position: absolute;
}

.circular-3d-button .outter-ring {
  width: 100%;
  height: 100%;
  background-color: rgba(20, 20, 20, 0.7);
  z-index: 1;
}

.circular-3d-button .inner-ring {
  width: 87.5%;
  height: 87.5%;
  background: linear-gradient(45deg, #ccc, #fff);
  z-index: 2;
}

.circular-3d-button .button {
  width: 83.34%;
  height: 83.34%;
  background: linear-gradient(225deg, #ccc, #fff);
  z-index: 3;
  cursor: pointer;
}

.circular-3d-button .button:active {
  box-shadow: 0 0 20px var(--Brand3Color);
  transform: scale(1.02);
}

.circular-3d-button .button .button-icon {
  margin: 20%;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
