.door-tab-content {
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.door-tab-content .button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  height: auto;
  flex-grow: 1;
  flex-basis: 50%;
  min-width: 180px;
  max-width: 240px;
}
.door-tab-content .button-container > .button {
  align-self: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  aspect-ratio: 1 / 1;
  max-height: 240px;
}

.door-tab-content .button-container > .button.green-icon .button-icon {
  /* Filters generated by concatenating two filters:
   * 1. Full black filter (brightness(0) saturate(100))
   * 2. Final desired color from filter generated with https://isotropic.co/tool/hex-color-to-css-filter
   */
  filter: brightness(0) saturate(100) invert(52%) sepia(100%) saturate(441%)
    hue-rotate(71deg) brightness(104%) contrast(140%);
}

.door-tab-content .button-container > .button.red-icon .button-icon {
  /* Filters generated by concatenating two filters:
   * 1. Full black filter (brightness(0) saturate(100))
   * 2. Final desired color from filter generated with https://isotropic.co/tool/hex-color-to-css-filter
   */
  filter: brightness(0) saturate(100) invert(14%) sepia(100%) saturate(6777%)
    hue-rotate(7deg) brightness(113%) contrast(121%);
}

.door-tab-content .label {
  font-size: clamp(1.2rem, 10vw, 1.75rem);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 5px;
  position: relative;
  z-index: 2;
}

/* Handling for when buttons would wrap */
@media only screen and (max-width: 560px) {
  .door-tab-content {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .door-tab-content .button-container {
    flex-grow: 1;
    flex-basis: 50%;
    height: initial;
    width: 100%;
    min-width: initial;
    max-width: initial;
  }

  .door-tab-content .button-container > .button {
    width: auto;
    height: 100%;
    max-width: min(240px, 100vw);
    max-height: min(240px, 100vw);
  }
  .door-tab-content .button-container > .label {
    max-width: min(240px, 90vw);
  }
}
/* End of handling for when buttons would wrap */

/* Lock tab */
.door-tab-content.lock-tab {
  flex-direction: column;
  flex-wrap: nowrap;
}
.door-tab-content.lock-tab .button-container {
  flex-grow: 1;
  flex-basis: 50%;
  height: initial;
  width: 100%;
  min-width: initial;
  max-width: initial;
  max-height: 320px;
}
.door-tab-content.lock-tab .button-container > .button {
  width: auto;
  height: 100%;
  max-width: min(240px, 100vw);
  max-height: min(240px, 100vw);
}
.door-tab-content.lock-tab .button-container > .label {
  max-width: min(240px, 90vw);
}
.door-tab-content.lock-tab .informationals {
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width: 300px;
  margin-bottom: 10px;
  position: relative;
}
.door-tab-content.lock-tab .informationals > .informational {
  display: flex;
  flex-direction: column;
  border-radius: 25%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.56);
  color: white;
  align-items: center;
  flex-grow: 0;
  gap: 5px;
  height: 8vh;
}
.door-tab-content.lock-tab .informationals > .informational.left-informational {
  position: absolute;
  left: 0px;
}
.door-tab-content.lock-tab
  .informationals
  > .informational
  > .informational-icon {
  height: 80%;
  aspect-ratio: 1 / 1;
}
.door-tab-content.lock-tab
  .informationals
  > .informational
  > .informational-label {
  font-size: 50%;
}
/* End of lock tab */

/* Handling for magic foil tab */
.door-tab-content > .magic-foil-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.7;
}
/* End of handling of magic foil tab */
